/*** Headlines + Text ***/

.mco-view-component-container-basic-headline + .mco-view-component-container-basic-text {
	margin-top: -40px;
}
.mco-view-component-container-basic-headline--1
	+ .mco-view-component-container-basic-text--large {
	margin-top: -32px;
}
.mco-view-component-container-basic-headline--4 + .mco-view-component-container-basic-text {
	margin-top: -40px;
}
.mco-view-component-container-basic-headline--1
	+ .mco-view-component-container-basic-headline--2 {
	margin-top: -40px;
}
.mco-view-component-container-basic-headline--2
	+ .mco-view-component-container-basic-headline--3 {
	margin-top: -40px;
}
.mco-view-component-container-basic-headline--1
	+ .mco-view-component-container-basic-headline--2
	+ .mco-view-component-container-basic-text {
	margin-top: -24px;
}
.mco-view-component-container-basic-headline--1
	+ .mco-view-component-container-basic-headline--2
	h2 {
	font-size: 16px;
	font-weight: 700;
}
.mco-view-component-container-basic-headline--2
	+ .mco-view-component-container-basic-headline--3
	h3 {
	font-size: 16px;
	font-weight: 700;
}
.mco-view-component-container-basic-headline--2
	+ .mco-view-component-container-basic-headline--3
	+ .mco-view-component-container-basic-text {
	margin-top: -24px;
}

/* Headlien + HTML */

.mco-view-component-container-basic-headline
	+ .mco-view-component-container-basic-html-extended {
	margin-top: -32px !important;
}

@media all and (min-width: 960px) {
	.mco-view-component-container-basic-headline + .mco-view-component-container-basic-text {
		margin-top: -46px;
	}
	.mco-view-component-container-basic-headline--1
		+ .mco-view-component-container-basic-text--large {
		margin-top: -40px;
	}
	.mco-view-component-container-basic-headline--4 + .mco-view-component-container-basic-text {
		margin-top: -64px;
	}
	.mco-view-component-container-basic-headline--1
		+ .mco-view-component-container-basic-headline--2 {
		margin-top: -64px;
	}
	.mco-view-component-container-basic-headline--2
		+ .mco-view-component-container-basic-headline--3 {
		margin-top: -64px;
	}
	.mco-view-component-container-basic-headline--1
		+ .mco-view-component-container-basic-headline--2
		+ .mco-view-component-container-basic-text {
		margin-top: -32px;
	}
	.mco-view-component-container-basic-headline--2
		+ .mco-view-component-container-basic-headline--3
		+ .mco-view-component-container-basic-text {
		margin-top: -32px;
	}

	/* Headlien + HTML */

	.mco-view-component-container-basic-headline
		+ .mco-view-component-container-basic-html-extended {
		margin-top: -40px !important;
	}
}
@media all and (min-width: 1280px) {
	.mco-view-component-container-basic-headline + .mco-view-component-container-basic-text {
		margin-top: -74px;
	}
	.mco-view-component-container-basic-headline--1
		+ .mco-view-component-container-basic-text--large {
		margin-top: -68px;
	}
	.mco-view-component-container-basic-headline--4 + .mco-view-component-container-basic-text {
		margin-top: -92px;
	}
	.mco-view-component-container-basic-headline--1
		+ .mco-view-component-container-basic-headline--2 {
		margin-top: -88px;
	}
	.mco-view-component-container-basic-headline--2
		+ .mco-view-component-container-basic-headline--3 {
		margin-top: -88px;
	}
	.mco-view-component-container-basic-headline--1
		+ .mco-view-component-container-basic-headline--2
		+ .mco-view-component-container-basic-text {
		margin-top: -48px;
	}
	.mco-view-component-container-basic-headline--2
		+ .mco-view-component-container-basic-headline--3
		+ .mco-view-component-container-basic-text {
		margin-top: -48px;
	}

	/* Headlien + HTML */

	.mco-view-component-container-basic-headline
		+ .mco-view-component-container-basic-html-extended {
		margin-top: -64px !important;
	}
}

/*** Button ***/

* + .mco-view-component-container-extension-button {
	margin-top: -32px !important;
}
@media all and (min-width: 960px) {
	* + .mco-view-component-container-extension-button {
		margin-top: -40px !important;
	}
}

/*** Quicksearch ***/

.mco-elements > .mco-view-component-container-extension-quickbooking:first-child {
	margin-top: 32px !important;
}

/*** Dropdown ***/

.mco-view-component-container-basic-dropdown-group
	+ .mco-view-component-container-basic-dropdown-group {
	margin-top: -57px !important;
}
.mco-view-component-container-basic-dropdown-group
	.mco-view-component-container-basic-headline
	+ .mco-view-component-container-basic-text {
	margin: 0 !important;
}
.mco-view-component-container-basic-dropdown-group
	.mco-view-component-container-basic-headline--2
	+ .mco-view-component-container-basic-headline--3 {
	margin-top: -24px !important;
}
.mco-view-component-container-basic-headline
	+ .mco-view-component-container-basic-dropdown-group {
	margin-top: -40px !important;
}

@media all and (min-width: 960px) {
	.mco-view-component-container-basic-dropdown-group
		+ .mco-view-component-container-basic-dropdown-group {
		margin-top: -81px !important;
	}
	.mco-view-component-container-basic-dropdown-group
		+ .mco-view-component-container-basic-dropdown-group
		> div {
		border-top: none !important;
	}
	.mco-view-component-container-basic-dropdown-group .mco-gallery-04-swiper-nav__prev {
		left: auto !important;
		margin-left: auto !important;
		right: 64px !important;
	}
	.mco-view-component-container-basic-dropdown-group .mco-gallery-04-swiper-nav__next {
		left: auto !important;
		margin-left: auto !important;
		right: 0 !important;
	}
	.mco-view-component-container-basic-headline
		+ .mco-view-component-container-basic-dropdown-group {
		margin-top: -56px !important;
	}
}
@media all and (min-width: 1280px) {
	.mco-view-component-container-basic-dropdown-group
		+ .mco-view-component-container-basic-dropdown-group {
		margin-top: -101px !important;
	}
	.mco-view-component-container-basic-headline
		+ .mco-view-component-container-basic-dropdown-group {
		margin-top: -64px !important;
	}
}

/*** Gallery ***/

.mco-view-component-container-basic-headline--2 + .mco-view-component-container-basic-gallery {
	margin-top: -32px;
}
@media all and (min-width: 960px) {
	.mco-view-component-container-basic-headline--2
		+ .mco-view-component-container-basic-gallery {
		margin-top: -124px;
	}
}
@media all and (min-width: 1280px) {
	.mco-view-component-container-basic-headline--2
		+ .mco-view-component-container-basic-gallery {
		margin-top: -152px;
	}
}

/*** Room List ***/

@media all and (min-width: 1280px) {
	.extension-image-list-combo + .mco-view-component-container-tourism-room-list {
		margin-top: -104px;
	}
}

/*** List ***/

.mco-view-component-container-basic-headline + .mco-view-component-container-basic-list {
	margin-top: -40px;
}

@media all and (min-width: 1280px) {
	.mco-view-component-container-basic-headline + .mco-view-component-container-basic-list {
		margin-top: -74px;
	}
}

/*** Ebook ***/

.mco-view-component-container-extension-ebook + .mco-view-component-container-extension-ebook {
	margin-top: -24px;
}
.mco-view-component-container-extension-ebook
	+ .mco-view-component-container-extension-ebook
	> div {
	border-top: 1px solid #132941;
	padding-top: 32px !important;
}
@media all and (min-width: 960px) {
	.mco-view-component-container-extension-ebook
		+ .mco-view-component-container-extension-ebook {
		margin-top: -48px;
	}
}
@media all and (min-width: 1280px) {
	.mco-view-component-container-extension-ebook
		+ .mco-view-component-container-extension-ebook {
		margin-top: -72px;
	}
}

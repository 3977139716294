/*** Consent ***/

.mco-content .mco-container:first-child {
	margin-top: 0;
}

/*** Layout ***/

/*** Headline ***/

.mco-view-component-container-basic-headline > * {
	max-width: 980px;
}

/*** Text ***/

.mco-text-01 a {
	text-decoration: underline;
	text-underline-offset: 2px;
	text-decoration-thickness: 1.5px;
}
.mco-text-01 a:hover {
	opacity: 0.8;
}
.mco-view-component-container-basic-text .mco-text-01 * {
	font-size: clamp(19px, 4vw, 23px);
	max-width: 980px;
}
.mco-view-component-container-basic-text--large .mco-text-01 * {
	font-family: "Junicode";
	font-size: clamp(30px, 4vw, 48px);
	font-weight: normal;
	line-height: 1.0625;
}
@media all and (min-width: 960px) {
	.mco-view-component-container-basic-text--large .mco-text-01 * {
		letter-spacing: -0.2px !important;
	}
}

/*** Table ***/

.mco-table-01 {
	max-width: 980px;
}
.mco-table-01__headline {
	color: #132941 !important;
}
.mco-table-01__row th {
	background: none !important;
	color: #132941 !important;
	line-height: 1.5 !important;
}
.mco-table-01__cell {
	border-bottom: 1px solid #132941 !important;
	padding: 23px 8px 16px !important;
}
.mco-table-01__cell--sticky {
	background: #f3efe7 !important;
	padding-left: 0 !important;
	max-width: none !important;
	min-width: 100px !important;
	width: auto !important;
}
.mco-table-01__swipePopUp {
	background: rgba(0, 0, 0, 0.6) !important;
}
.mco-table-01__swipePopUp p {
	color: #ffffff !important;
}
.mco-table-01__swipePopUp svg path {
	fill: #ffffff !important;
}
@media all and (min-width: 960px) {
	.mco-table-01__headline {
		margin-bottom: 16px !important;
	}
}

/*** List ***/

.mco-list-01__headline {
	color: #132941 !important;
	margin-bottom: 32px !important;
}
.mco-list-01__list-item > div {
	display: none !important;
}
.mco-list-01__list-item > span {
	font-weight: 500;
	font-size: 16px !important;
	padding-left: 18px;
	position: relative;
}
.mco-list-01__list-item > span:before {
	background: #132941;
	border-radius: 50%;
	content: "";
	height: 6px;
	left: 0;
	position: absolute;
	top: 7px;
	width: 6px;
}
.mco-list-01__list-item a {
	text-decoration: underline;
	text-underline-offset: 2px;
	text-decoration-thickness: 1.5px;
}
.mco-list-01__list-item a:hover {
	opacity: 0.8;
}
@media all and (min-width: 600px) {
	.mco-view-component-container-basic-list--two-columns .mco-list-01__list {
		column-count: 2;
		display: table-cell !important;
		column-gap: 16px;
		margin: 0 !important;
		width: 100% !important;
	}
	.mco-view-component-container-basic-list--two-columns .mco-list-01__list-item {
		flex-basis: unset !important;
		margin-bottom: 8px !important;
		max-width: none !important;
		padding: 0 !important;
		position: relative;
	}
}
@media all and (min-width: 960px) {
	.mco-list-01 {
		max-width: 980px;
	}
	.mco-list-01__headline {
		margin-bottom: 48px !important;
	}
	.mco-view-component-container-basic-list--two-columns .mco-list-01__list {
		column-gap: 100px;
	}
}

/*** Singleimage ***/

.mco-singleimage-01__content {
	padding: 16px !important;
}
.mco-singleimage-01__content-text {
	font-weight: 500 !important;
}

/*** References ***/

.mco-references-02__container {
	border: none !important;
	padding: 0 !important;
}
.mco-references-02__headline {
	color: #132941 !important;
	margin-bottom: 16px !important;
}
.mco-references-02__list-item button {
	color: #132941 !important;
	height: auto !important;
	min-height: 50px !important;
}
.mco-references-02__list-item button p {
	font-size: 13px !important;
	font-weight: 700 !important;
	letter-spacing: 1px !important;
}
@media all and (min-width: 960px) {
	.mco-references-02__list {
		flex: 1 0 60% !important;
		justify-content: flex-start !important;
	}
	.mco-references-02__list-item {
		margin: 0 8px 16px !important;
	}
}

/*** Dropdown ***/

.mco-dropdown-01 {
	border-bottom: 1px solid #132941 !important;
	border-top: 1px solid #132941 !important;
	max-width: 980px;
}
.mco-view-component-container-basic-dropdown-group--full-width .mco-dropdown-01 {
	max-width: none;
}
.mco-dropdown-01__summary {
	background: none !important;
	padding: 0 !important;
}
.mco-dropdown-01__summary > div:first-child {
	margin: 18px 0 12px !important;
}
.mco-dropdown-01__headline {
	font-size: clamp(17px, 3vw, 20px) !important;
	font-weight: 700 !important;
	text-transform: uppercase;
}
.mco-dropdown-01--open .MuiIconButton-root > span * {
	display: none !important;
}
.mco-dropdown-01--open .MuiIconButton-root > span:first-child:before {
	background: #132941;
	content: "";
	display: flex;
	height: 1.5px;
	width: 12.5px;
}
@media all and (min-width: 960px) {
	.mco-dropdown-01__summary > div:first-child {
		margin: 32px 0 28px !important;
	}
}

/*** Download ***/

.mco-download-01 a {
	align-items: center;
	border: 1.5px solid #132941;
	padding: 12px 32px 12px 16px;
}
.mco-download-01 a:hover {
	color: #132941 !important;
}
.mco-download-01__title {
	font-size: 13px !important;
	font-weight: 700 !important;
	text-transform: uppercase;
	letter-spacing: 1px;
}
.mco-download-01__caption {
	display: table;
	font-size: 10px !important;
	opacity: 0.5;
}
.mco-download-01__link svg {
	width: 24px !important;
}
.mco-view-component-container-basic-download-group {
	display: flex !important;
	flex-direction: column;
	gap: 8px;
}
.mco-view-component-container-basic-download-group .mco-view-component-container {
	padding-left: 0;
	padding-right: 0;
}

/*** Video ***/

.mco-video-02 {
	background: none !important;
	padding: 0 !important;
}
.mco-video-02__title {
	color: #132941 !important;
	font-size: clamp(22px, 4vw, 29px) !important;
	font-weight: 400 !important;
	text-transform: unset !important;
}
.mco-video-02__content {
	padding: 24px 0 0 !important;
}
.mco-video-02__content .MuiBox-root {
	margin-top: 24px !important;
}
.mco-video-02__text {
	font-size: 16px !important;
	font-weight: 500 !important;
	line-height: 1.5 !important;
}
@media all and (min-width: 960px) {
	.mco-video-02__content {
		padding: 56px !important;
	}
}

/*** Image-Text ***/

.mco-view-component-container-basic-imagetext--large {
	overflow: hidden;
	padding: 0 !important;
}
.mco-imagetext-01__title {
	font-size: clamp(22px, 4vw, 29px) !important;
	font-weight: 400 !important;
	color: #132941 !important;
	text-transform: unset !important;
}
.mco-view-component-container-basic-imagetext--large .mco-imagetext-01__title {
	font-family: "Junicode" !important;
	font-size: 34px !important;
	font-weight: 400 !important;
	letter-spacing: -0.2px !important;
	line-height: 1.05 !important;
}
.mco-imagetext-01__text {
	font-size: 15px !important;
	font-weight: 500 !important;
	line-height: 1.6875 !important;
}
.mco-imagetext-01__text a {
	font-weight: 500 !important;
	text-decoration: underline;
	text-underline-offset: 2px;
	text-decoration-thickness: 1.5px;
}
.mco-imagetext-01__text a:hover {
	opacity: 0.8;
}
.mco-view-component-container-basic-imagetext--large .mco-imagetext-01__content {
	padding: 16px 16px 0;
}
.mco-imagetext-01__subtitle {
	color: #132941 !important;
	font-family: "Gatter Sans" !important;
	font-size: 13px !important;
	letter-spacing: 1px !important;
	margin-bottom: 10px !important;
	text-transform: uppercase !important;
}
@media all and (min-width: 960px) {
	.mco-imagetext-01 {
		align-items: center !important;
	}
	.mco-imagetext-01__text {
		font-size: 16px !important;
	}
	.mco-view-component-container-basic-imagetext--large .mco-imagetext-01__content {
		padding: 0 16px;
	}
}
@media all and (min-width: 1280px) {
	.mco-imagetext-01__title {
		margin-bottom: 16px !important;
	}
	.mco-imagetext-01 {
		grid-column-gap: 140px !important;
		grid-template-columns: 1fr 1.25fr !important;
	}
	.mco-imagetext-01__wrapper--right {
		grid-template-columns: 1.25fr 1fr !important;
	}
}

@media all and (min-width: 1280px) {
	.mco-view-component-container-basic-imagetext--large {
		max-width: none !important;
	}
	.mco-view-component-container-basic-imagetext--large .mco-imagetext-01 {
		display: grid !important;
		grid-column-gap: unset !important;
		grid-template-columns: 1fr 80px 80px 80px 80px 80px 80px 80px 80px 80px 80px 80px 80px 80px 80px 80px 80px 1fr !important;
		padding: 0 !important;
	}
	.mco-view-component-container-basic-imagetext--large .mco-imagetext-01__title {
		font-size: 58px !important;
		letter-spacing: -0.4px !important;
	}
	.mco-view-component-container-basic-imagetext--large .mco-imagetext-01__content {
		max-width: none !important;
		flex-basis: unset !important;
		padding: 0 0 0 16px !important;
	}
	.mco-view-component-container-basic-imagetext--large .mco-imagetext-01__image {
		max-width: none !important;
		flex-basis: unset !important;
	}
	.mco-view-component-container-basic-imagetext--large .mco-imagetext-01__image img {
		max-width: 952px !important;
	}
	.mco-view-component-container-basic-imagetext--large .mco-imagetext-01__content {
		grid-column: 12 / 18 !important;
		padding: 0 16px 0 0 !important;
	}
	.mco-view-component-container-basic-imagetext--large .mco-imagetext-01__image {
		grid-column: 1 / 11 !important;
		margin-left: auto !important;
	}
	.mco-view-component-container-basic-imagetext--large
		.mco-imagetext-01__wrapper--left
		.mco-imagetext-01__content {
		grid-column: 2 / 8 !important;
		padding: 0 0 0 16px !important;
	}
	.mco-view-component-container-basic-imagetext--large
		.mco-imagetext-01__wrapper--left
		.mco-imagetext-01__image {
		grid-column: 9 / -1 !important;
		margin-left: unset !important;
	}
}
@media all and (min-width: 1440px) {
	.mco-view-component-container-basic-imagetext--large .mco-imagetext-01__content {
		padding: 0 20px 0 16px !important;
	}
	.mco-view-component-container-basic-imagetext--large
		.mco-imagetext-01__wrapper--left
		.mco-imagetext-01__content {
		padding: 0 16px 0 20px !important;
	}
}

/*** Singleteaser ***/

.mco-view-component-container-basic-singleteaser {
	overflow: hidden;
}
.mco-singleteaser-01__content {
	border: none !important;
	padding: 40px 16px 0 !important;
}
.mco-singleteaser-01__addition {
	color: #132941 !important;
	font-size: 13px !important;
	font-weight: bold !important;
	letter-spacing: 1px !important;
	margin-bottom: 10px !important;
}
.mco-singleteaser-01__title {
	color: #132941 !important;
	margin-bottom: 10px !important;
}
.mco-singleteaser-01__text {
	font-size: 15px !important;
	font-weight: 500 !important;
	line-height: 1.66 !important;
}
.mco-singleteaser-01__button {
	justify-content: flex-start !important;
}
.mco-view-component-container-basic-singleteaser--notext-right .mco-singleteaser-01__addition,
.mco-view-component-container-basic-singleteaser--notext-left .mco-singleteaser-01__addition {
	margin-bottom: 16px !important;
}
.mco-view-component-container-basic-singleteaser--notext-right .mco-singleteaser-01__title,
.mco-view-component-container-basic-singleteaser--notext-left .mco-singleteaser-01__title {
	font-family: "Junicode" !important;
	font-size: 34px !important;
	font-weight: 400 !important;
	letter-spacing: -0.2px !important;
	line-height: 1.05 !important;
}
.mco-view-component-container-basic-singleteaser--notext-right .mco-singleteaser-01__text,
.mco-view-component-container-basic-singleteaser--notext-left .mco-singleteaser-01__text {
	display: none !important;
}

@media all and (min-width: 600px) {
	.mco-singleteaser-01 > div {
		align-items: center !important;
	}
	.mco-singleteaser-01__content {
		max-width: 60% !important;
		flex-basis: 60% !important;
		padding: 0 40px 0 16px !important;
	}
	.mco-singleteaser-01__image {
		max-width: 40% !important;
		flex-basis: 40% !important;
	}
	.mco-view-component-container-basic-singleteaser--left .mco-singleteaser-01__content,
	.mco-view-component-container-basic-singleteaser--notext-left .mco-singleteaser-01__content {
		order: 2;
		padding: 0 16px 0 40px !important;
	}
	.mco-view-component-container-basic-singleteaser--left .mco-singleteaser-01__image,
	.mco-view-component-container-basic-singleteaser--notext-left .mco-singleteaser-01__image {
		order: 1;
	}
}

@media all and (min-width: 960px) {
	.mco-singleteaser-01 > div {
		align-items: center !important;
	}
	.mco-singleteaser-01__content {
		max-width: 40% !important;
		flex-basis: 40% !important;
	}
	.mco-singleteaser-01__image {
		max-width: 60% !important;
		flex-basis: 60% !important;
	}
	.mco-view-component-container-basic-singleteaser--notext-right
		.mco-singleteaser-01__addition,
	.mco-view-component-container-basic-singleteaser--notext-left
		.mco-singleteaser-01__addition {
		margin-bottom: 32px !important;
	}
	.mco-view-component-container-basic-singleteaser--notext-right .mco-singleteaser-01__title,
	.mco-view-component-container-basic-singleteaser--notext-left .mco-singleteaser-01__title {
		font-size: 58px !important;
		letter-spacing: -0.4px !important;
	}
}

@media all and (min-width: 1280px) {
	.mco-view-component-container-basic-singleteaser {
		max-width: none !important;
	}
	.mco-singleteaser-01 > div {
		display: grid !important;
		grid-template-columns: 1fr 80px 80px 80px 80px 80px 80px 80px 80px 80px 80px 80px 80px 80px 80px 80px 80px 1fr !important;
		padding: 0 !important;
	}
	.mco-singleteaser-01__content {
		max-width: none !important;
		flex-basis: unset !important;
		grid-column: 2 / 8;
		padding: 0 0 0 16px !important;
	}
	.mco-singleteaser-01__image {
		max-width: none !important;
		flex-basis: unset !important;
		grid-column: 9 / -1;
	}
	.mco-singleteaser-01__image img {
		max-width: 952px !important;
	}
	.mco-view-component-container-basic-singleteaser--left .mco-singleteaser-01__content,
	.mco-view-component-container-basic-singleteaser--notext-left .mco-singleteaser-01__content {
		grid-column: 12 / 18;
		padding: 0 16px 0 0 !important;
	}
	.mco-view-component-container-basic-singleteaser--left .mco-singleteaser-01__image,
	.mco-view-component-container-basic-singleteaser--notext-left .mco-singleteaser-01__image {
		grid-column: 1 / 11;
		margin-left: auto !important;
	}
}
@media all and (min-width: 1440px) {
	.mco-singleteaser-01__content {
		padding: 0 20px 0 16px !important;
	}
	.mco-view-component-container-basic-singleteaser--left .mco-singleteaser-01__content,
	.mco-view-component-container-basic-singleteaser--notext-left .mco-singleteaser-01__content {
		padding: 0 16px 0 20px !important;
	}
}

/*** Form ***/

.mco-view-component-container-basic-form .mco-form {
	gap: 24px 12px !important;
}
.mco-view-component-container-basic-form
	.mco-form-input:not(.mco-form-textarea):not(.mco-form-file)
	label,
.mco-view-component-container-basic-form .mco-form-date label,
.mco-view-component-container-basic-form .mco-form-select label {
	font-size: 13px !important;
	font-weight: bold !important;
	letter-spacing: 1px !important;
	text-transform: uppercase !important;
	transform: translate(0px, 24px) scale(1) !important;
}
.mco-view-component-container-basic-form
	.mco-form-input:not(.mco-form-textarea):not(.mco-form-file)
	label.MuiInputLabel-shrink,
.mco-view-component-container-basic-form .mco-form-date label.MuiInputLabel-shrink,
.mco-view-component-container-basic-form .mco-form-select label.MuiInputLabel-shrink {
	color: #132941 !important;
	transform: translate(0, 4px) scale(0.75) !important;
	text-transform: unset !important;
}
.mco-view-component-container-basic-form
	.mco-form-input:not(.mco-form-textarea):not(.mco-form-file)
	input,
.mco-view-component-container-basic-form .mco-form-date input {
	font-size: 13px !important;
	font-weight: bold !important;
	letter-spacing: 1px !important;
	padding: 24px 16px 16px 0 !important;
}
.mco-view-component-container-basic-form .mco-form-select .MuiSelect-root {
	font-size: 13px !important;
	font-weight: bold !important;
	letter-spacing: 1px !important;
	padding: 12px 32px 16px 0 !important;
	text-transform: uppercase !important;
}
.mco-view-component-container-basic-form
	.mco-form-input:not(.mco-form-textarea):not(.mco-form-file)
	fieldset,
.mco-view-component-container-basic-form .mco-form-date fieldset,
.mco-view-component-container-basic-form .mco-form-select fieldset {
	border: none !important;
	border-bottom: 1.5px solid #132941 !important;
	padding: 0 !important;
	text-transform: uppercase !important;
}
.mco-view-component-container-basic-form .mco-form-textarea {
	margin-top: 16px !important;
}
.mco-view-component-container-basic-form .mco-form-textarea fieldset {
	border: 1.5px solid #132941 !important;
	top: 0 !important;
}
.mco-view-component-container-basic-form .mco-form-textarea label,
.mco-view-component-container-basic-form .mco-form-textarea textarea {
	font-size: 13px !important;
	font-weight: bold !important;
	letter-spacing: 1px !important;
	text-transform: uppercase !important;
}
.mco-view-component-container-basic-form .mco-form-textarea label.MuiInputLabel-shrink {
	display: none !important;
	transform: unset !important;
}
.mco-view-component-container-basic-form .mco-form-textarea legend {
	display: none !important;
}
.mco-view-component-container-basic-form .mco-form-headline > * {
	font-size: clamp(22px, 4vw, 29px) !important;
	font-weight: 400 !important;
	margin: 32px 0 0 !important;
}
.mco-view-component-container-basic-form .mco-form-date svg {
	width: 16px !important;
}
.mco-view-component-container-basic-form .mco-form-checkbox .MuiFormControlLabel-label span {
	font-size: 13px !important;
	font-weight: 500 !important;
}
.mco-view-component-container-basic-form .mco-form-checkbox .Mui-checked svg {
	color: #132941 !important;
}
.mco-view-component-container-basic-form .mco-form-file input,
.mco-view-component-container-basic-form .mco-form-file label {
	font-size: 13px !important;
	font-weight: bold !important;
	letter-spacing: 1px !important;
	text-transform: uppercase !important;
}
.mco-view-component-container-basic-form .mco-form-file fieldset {
	border: 1.5px solid #132941 !important;
}
.mco-view-component-container-basic-form .mco-form-file label.MuiInputLabel-shrink {
	display: none !important;
	transform: unset !important;
}
.mco-view-component-container-basic-form .mco-form-file legend {
	display: none !important;
}
#menu-Auswahlfeld .MuiListItem-root {
	font-size: 13px !important;
	font-weight: 500 !important;
	letter-spacing: 1px !important;
	text-transform: uppercase !important;
}
.mco-view-component-container-basic-form .mco-form .MuiFormHelperText-root {
	font-size: 10px !important;
	letter-spacing: 0.4px !important;
	margin: 8px 0 0 0 !important;
	text-transform: unset !important;
}
.mco-view-component-container-basic-form .mco-form-message-error {
	border: 1.5px solid #da6464 !important;
}
.mco-view-component-container-basic-form .mco-form-message-success {
	border: 1.5px solid #9fbd80 !important;
}
.mco-view-component-container-basic-form
	.mco-form-spam-google-recaptcha-v2
	+ .mco-form-button-wrapper {
	margin-top: -24px !important;
}

@media all and (min-width: 960px) {
	.mco-view-component-container-basic-form .mco-form {
		display: grid !important;
		grid-template-columns: repeat(3, 1fr) !important;
		grid-column-gap: 40px !important;
		grid-row-gap: 24px !important;
		max-width: 980px !important;
	}
	.mco-view-component-container-basic-form .mco-form-headline,
	.mco-view-component-container-basic-form .mco-form-textarea,
	.mco-view-component-container-basic-form .mco-form-checkbox,
	.mco-view-component-container-basic-form .mco-form-file,
	.mco-view-component-container-basic-form .mco-form-text,
	.mco-view-component-container-basic-form .mco-form-button-wrapper,
	.mco-view-component-container-basic-form .mco-form-spam-google-recaptcha-v2 {
		grid-column: 1 / -1 !important;
	}
}
@media all and (min-width: 1280px) {
	.mco-view-component-container-basic-form .mco-form {
		grid-column-gap: 58px !important;
	}
}

/*** Gallery ***/

/* .mco-gallery-04 {
	margin-left: calc(50% - 640px) !important;
	padding-left: 16px;
} */
.mco-gallery-04 > .swiper {
	padding-bottom: 56px !important;
}
.mco-gallery-04-swiper__item {
	overflow: hidden;
}
.mco-gallery-04-swiper__item img {
	transition: transform 800ms ease !important;
}
@media (hover: hover) {
	.mco-gallery-04-swiper__item:hover img {
		transform: scale(1.05);
	}
}
.mco-gallery-04-swiper-nav__next,
.mco-gallery-04-swiper-nav__prev {
	background: none !important;
	border-radius: unset !important;
	bottom: 0 !important;
	cursor: pointer;
	display: none !important;
	height: 48px !important;
	top: auto !important;
	transform: translate(-50%, 0) !important;
	user-select: none !important;
	width: 48px !important;
}
.mco-gallery-04-swiper-nav__next {
	left: calc(50% + 28px) !important;
	right: auto !important;
}
.mco-gallery-04-swiper-nav__prev {
	left: calc(50% - 28px) !important;
}
.mco-gallery-04-swiper-nav__next svg,
.mco-gallery-04-swiper-nav__prev svg {
	width: 48px !important;
}
@media all and (min-width: 960px) {
	.mco-gallery-04 .swiper {
		padding-bottom: 0 !important;
		padding-top: 90px !important;
	}
	.mco-gallery-04-swiper-nav__next {
		display: inline-flex !important;
		left: auto !important;
		right: 16px !important;
		top: 0 !important;
		transform: none !important;
	}
	.mco-gallery-04-swiper-nav__prev {
		display: inline-flex !important;
		left: auto !important;
		right: 72px !important;
		top: 0 !important;
		transform: none !important;
	}
}
@media all and (min-width: 1280px) {
	.mco-gallery-04-swiper-nav__next {
		left: 50% !important;
		right: auto !important;
		margin-left: 580px !important;
	}
	.mco-gallery-04-swiper-nav__prev {
		left: 50% !important;
		right: auto !important;
		margin-left: 512px !important;
	}
}
@keyframes zoom-in-zoom-out {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}

/* Textbox */

.mco-view-component-container-basic-gallery--textbox {
	max-width: 1700px !important;
}
.mco-gallery-05__item,
.mco-gallery-05__swiperRoot,
.mco-gallery-05__item-content {
	background: #da9465 !important;
}
.mco-gallery-05__item-title {
	font-size: 22px !important;
	font-weight: 400 !important;
	line-height: 1.36 !important;
}
.mco-gallery-05-swiper-nav__prev svg,
.mco-gallery-05-swiper-nav__next svg {
	width: 48px !important;
}
.swiper-pagination-fraction {
	bottom: auto !important;
	left: auto !important;
	width: auto !important;
	position: relative !important;
}

@media all and (min-width: 960px) {
	.mco-gallery-05__item,
	.mco-gallery-05__swiperRoot {
		background: none !important;
	}
	.mco-gallery-05__item {
		min-height: 410px !important;
	}
	.mco-gallery-05__item-title {
		font-size: 29px !important;
	}
}

@media all and (min-width: 1280px) {
	.mco-gallery-05__bottom-wrapper {
		width: 44% !important;
		bottom: 160px !important;
		padding: 0px 56px 40px !important;
	}
	.mco-gallery-05__item {
		grid-template-rows: 1fr 1fr 160px !important;
		grid-template-columns: 56% 44% !important;
	}
	.mco-gallery-05__item-content {
		padding: 56px 56px 160px !important;
	}
}

/*** Teasergroup/Teaser ***/

.mco-view-component-container-basic-teaser--slider-default,
.mco-view-component-container-basic-teaser--slider,
.mco-view-component-container-tourism-packages-teaser {
	overflow: hidden;
	padding-top: 32px !important;
	position: relative;
}
.mco-view-component-container-basic-teaser--slider-default::before {
	background: #c9c2d0;
	content: "";
	height: 50%;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
}
.mco-view-component-container-basic-teaser--slider-default .mco-teaser-list-01__textbox,
.mco-view-component-container-basic-teaser--slider .mco-teaser-list-01__textbox,
.mco-view-component-container-tourism-packages-teaser .mco-teaser-list-01__textbox {
	grid-gap: 12px !important;
	margin-bottom: 16px !important;
	max-width: 650px !important;
	padding-right: 16px !important;
}
.mco-view-component-container-basic-teaser--slider-default .mco-teaser-list-01__headline,
.mco-view-component-container-basic-teaser--slider .mco-teaser-list-01__headline,
.mco-view-component-container-tourism-packages-teaser .mco-teaser-list-01__headline {
	font-family: "Junicode" !important;
	font-size: 34px !important;
	letter-spacing: -0.2px !important;
	line-height: 1.2 !important;
	grid-row: 2 / 3;
}
.mco-view-component-container-basic-teaser--slider-default .mco-teaser-list-01__text,
.mco-view-component-container-basic-teaser--slider .mco-teaser-list-01__text,
.mco-view-component-container-tourism-packages-teaser .mco-teaser-list-01__text {
	font-size: 13px !important;
	font-weight: bold !important;
	letter-spacing: 1px !important;
	text-transform: uppercase !important;
}
.mco-view-component-container-basic-teaser--slider-default > div,
.mco-view-component-container-basic-teaser--slider > div,
.mco-view-component-container-tourism-packages-teaser > div {
	padding-left: 16px !important;
}
.mco-view-component-container-basic-teaser--slider .mco-teaser-list-01__text,
.mco-view-component-container-tourism-packages-teaser .mco-teaser-list-01__text,
.mco-view-component-container-basic-teaser--slider-default .swiper-slide > div,
.mco-view-component-container-tourism-packages-teaser .swiper-slide > div {
	padding-right: 16px !important;
}
.mco-view-component-container-basic-teaser--slider-default .mco-teaser-01,
.mco-package-teaser {
	background: none !important;
}
.mco-view-component-container-basic-teaser--slider-default .mco-teaser-01 picture,
.mco-package-teaser picture {
	display: block;
	overflow: hidden;
	position: relative;
}
.mco-view-component-container-basic-teaser--slider-default .mco-teaser-01 picture img,
.mco-package-teaser picture img {
	transition: transform 800ms ease !important;
}
@media (hover: hover) {
	.mco-view-component-container-basic-teaser--slider-default .mco-teaser-01:hover picture img,
	.mco-package-teaser:hover img {
		transform: scale(1.05);
	}
}
.mco-view-component-container-basic-teaser--slider-default .mco-teaser-01__content {
	padding: 16px 0 0 !important;
}
.mco-view-component-container-basic-teaser--slider-default .mco-teaser-01__title {
	font-weight: 700 !important;
	font-size: 17px !important;
	text-transform: uppercase !important;
}
.mco-view-component-container-basic-teaser--slider-default .mco-teaser-01__bottom {
	margin-top: 0 !important;
}
.mco-view-component-container-basic-teaser--slider-default .mco-teaser-01__text {
	font-weight: 500 !important;
	font-size: 15px !important;
	line-height: 1.65 !important;
}
.mco-view-component-container-basic-teaser--slider-default .mco-teaser-01__price {
	display: flex !important;
	max-width: 100% !important;
	flex-basis: 100% !important;
	flex-direction: row-reverse !important;
	gap: 8px !important;
	justify-content: flex-end !important;
}
.mco-view-component-container-basic-teaser--slider-default .mco-teaser-01__price > p {
	color: #132941 !important;
	font-weight: 700 !important;
	font-size: 13px !important;
	letter-spacing: 1px !important;
}

@media all and (min-width: 960px) {
	.mco-view-component-container-basic-teaser--slider-default,
	.mco-view-component-container-basic-teaser--slider,
	.mco-view-component-container-tourism-packages-teaser {
		padding-top: 56px !important;
	}
	.mco-view-component-container-basic-teaser--slider-default .mco-teaser-list-01__textbox,
	.mco-view-component-container-basic-teaser--slider .mco-teaser-list-01__textbox,
	.mco-view-component-container-tourism-packages-teaser .mco-teaser-list-01__textbox {
		grid-gap: 24px !important;
		margin-bottom: 0 !important;
	}
	.mco-view-component-container-basic-teaser--slider-default .mco-teaser-list-01__headline,
	.mco-view-component-container-basic-teaser--slider .mco-teaser-list-01__headline,
	.mco-view-component-container-tourism-packages-teaser .mco-teaser-list-01__headline {
		font-size: 56px !important;
		letter-spacing: -0.4px !important;
		line-height: 1.2 !important;
	}
	.mco-view-component-container-basic-teaser--slider-default .mco-teaser-list-01__swiperRoot,
	.mco-view-component-container-basic-teaser--slider .mco-teaser-list-01__swiperRoot,
	.mco-view-component-container-tourism-packages-teaser .mco-teaser-list-01__swiperRoot {
		padding-top: 40px !important;
	}
	.mco-view-component-container-basic-teaser--slider-default .swiper-navigation-wrapper,
	.mco-view-component-container-basic-teaser--slider .swiper-navigation-wrapper,
	.mco-view-component-container-tourism-packages-teaser .swiper-navigation-wrapper {
		position: absolute;
		top: -40px;
		max-width: 1264px;
		margin: 0 auto;
		left: 0;
		display: flex;
		gap: 24px;
		justify-content: flex-end;
		padding-right: 11px;
		width: 100%;
	}
	.mco-view-component-container-basic-teaser--slider-default .swiper-button-prev,
	.mco-view-component-container-basic-teaser--slider-default .swiper-button-next,
	.mco-view-component-container-basic-teaser--slider .swiper-button-prev,
	.mco-view-component-container-basic-teaser--slider .swiper-button-next,
	.mco-view-component-container-tourism-packages-teaser .swiper-button-prev,
	.mco-view-component-container-tourism-packages-teaser .swiper-button-next {
		bottom: auto !important;
		position: relative !important;
		transform: unset !important;
		left: auto !important;
		right: auto !important;
	}
	.mco-view-component-container-basic-teaser--slider-default .swiper-button-prev::after,
	.mco-view-component-container-basic-teaser--slider-default .swiper-button-next::after,
	.mco-view-component-container-basic-teaser--slider .swiper-button-prev::after,
	.mco-view-component-container-basic-teaser--slider .swiper-button-next::after,
	.mco-view-component-container-tourism-packages-teaser .swiper-button-prev::after,
	.mco-view-component-container-tourism-packages-teaser .swiper-button-next::after {
		content: none;
		display: none;
	}
	.mco-view-component-container-basic-teaser--slider-default .swiper-button-next,
	.mco-view-component-container-basic-teaser--slider .swiper-button-next,
	.mco-view-component-container-tourism-packages-teaser .swiper-button-next {
		margin-right: 4px;
	}
	.mco-view-component-container-basic-teaser--slider-default .swiper-button-lock,
	.mco-view-component-container-basic-teaser--slider .swiper-button-lock,
	.mco-view-component-container-tourism-packages-teaser .swiper-button-lock {
		display: none !important;
	}
	.mco-view-component-container-basic-teaser--slider-default .mco-teaser-01__content {
		padding: 20px 0 0 !important;
	}
	.mco-view-component-container-basic-teaser--slider-default .mco-teaser-01__title {
		font-size: 19px !important;
	}
	.mco-view-component-container-basic-teaser--slider-default .mco-teaser-01__text {
		font-size: 16px !important;
	}
}
@media all and (min-width: 1280px) {
	.mco-view-component-container-basic-teaser--slider-default > div,
	.mco-view-component-container-basic-teaser--slider > div,
	.mco-view-component-container-tourism-packages-teaser > div {
		margin-left: calc((100% - 1280px) / 2) !important;
	}
	.mco-view-component-container-basic-teaser--slider .mco-teaser-list-01__text,
	.mco-view-component-container-tourism-packages-teaser .mco-teaser-list-01__text,
	.mco-view-component-container-basic-teaser--slider-default .swiper-slide > div,
	.mco-view-component-container-tourism-packages-teaser .swiper-slide > div {
		padding-right: 20px !important;
	}
}

/* Slider ohne Text */

.mco-teaser-03 a:hover img {
	transform: none !important;
}
@media (hover: hover) {
	.mco-teaser-03 a:hover img {
		transform: scale(1.05) !important;
	}
}
.mco-view-component-container-basic-teaser--slider::before {
	background: #9fbd80;
	content: "";
	height: 50%;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
}
.mco-teaser-03__content:before {
	background: linear-gradient(
		180deg,
		rgba(19, 41, 65, 0.64) 0%,
		rgba(19, 41, 65, 0.4) 14.31%,
		rgba(19, 41, 65, 0) 46.59%,
		rgba(19, 41, 65, 0.4) 72.4%,
		rgba(19, 41, 65, 0.64) 100%
	) !important;
}
.mco-teaser-03__title {
	font-size: 19px !important;
	font-weight: 700 !important;
	text-transform: uppercase !important;
}
.mco-teaser-03__image img {
	transition: transform 800ms ease !important;
}

/* Textbox Slider */

.mco-view-component-container-basic-teaser--textbox {
	max-width: 1700px !important;
	padding: 0 !important;
}

/*** Packages Teaserslider ***/

.mco-view-component-container-tourism-packages-teaser {
	overflow: hidden;
}
.mco-view-component-container-tourism-packages-teaser .swiper-button-prev::after,
.mco-view-component-container-tourism-packages-teaser .swiper-button-next::after {
	display: none !important;
}

/*** Weather ***/

.mco-weather-03 {
	max-width: 980px !important;
}
.mco-weather-03-day {
	gap: 4px !important;
}
.mco-weather-03 .mco-weather-03-day,
.mco-weather-03 .mco-weather-03-day-today {
	background: none !important;
	border: 1px solid #132941 !important;
}
@media all and (min-width: 960px) {
	.mco-weather-03-day {
		gap: 0 !important;
	}
	.mco-weather-03 > div > div:not(:first-child) {
		width: 15% !important;
	}
	.mco-weather-03 .mco-weather-03-day,
	.mco-weather-03 .mco-weather-03-day-today {
		min-height: 160px !important;
	}
}

/*** Quote ***/

.mco-view-component-container-extension-quote {
	background: #9fbd80;
	padding: 24px 0 0 !important;
}
.mco-view-component-container-extension-quote--greyscale picture img {
	filter: grayscale(1) !important;
}
@media all and (min-width: 960px) {
	.mco-view-component-container-extension-quote {
		padding: 56px 0 !important;
	}
}

/*** Snowreport ***/

.mco-snowreport-01 {
	max-width: 980px !important;
}
.mco-snowreport-01 > div {
	border-bottom: 1px solid #132941 !important;
	padding: 16px 0 8px !important;
}
.mco-snowreport-01__column-label,
.mco-snowreport-01__column-label p {
	font-weight: 500 !important;
	font-size: 15px !important;
}
.mco-snowreport-01__column-value,
.mco-snowreport-01__column-value p {
	font-weight: bold !important;
	font-size: 15px !important;
}
.mco-snowreport-01__column-value > span {
	font-size: 13px !important;
	font-weight: 500 !important;
}
.mco-snowreport-01__column-label > div {
	margin-top: -6px !important;
}
.mco-snowreport-01__column--avalanche .MuiAvatar-root {
	font-family: Arial, Helvetica, sans-serif !important;
	height: 40px !important;
	width: 40px !important;
}
.mco-snowreport-01__column--avalanche .mco-snowreport-01__column-value {
	margin-top: -8px !important;
}

@media all and (min-width: 960px) {
	.mco-snowreport-01 > div {
		min-height: 80px !important;
		padding: 16px 0 8px !important;
	}
	.mco-snowreport-01__column-label,
	.mco-snowreport-01__column-label p {
		font-size: 19px !important;
	}
	.mco-snowreport-01__column-value,
	.mco-snowreport-01__column-value p {
		font-size: 19px !important;
	}
}

/*** Room list ***/

.mco-view-component-container-tourism-room-list {
	padding-top: 32px !important;
	position: relative;
}
.mco-view-component-container-tourism-room-list::before {
	background: #bcdbe4;
	content: "";
	height: 314px;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: -1;
}
@media all and (min-width: 960px) {
	.mco-view-component-container-tourism-room-list {
		padding-top: 48px !important;
	}
}

/*** Package list ***/

.mco-view-component-container-tourism-package-list {
	padding-top: 32px !important;
	position: relative;
}
.mco-view-component-container-tourism-package-list::before {
	background: #bcdbe4;
	content: "";
	height: 314px;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: -1;
}
@media all and (min-width: 960px) {
	.mco-view-component-container-tourism-package-list {
		padding-top: 48px !important;
	}
}

/*** Overlay ***/

.mco-overlay-close {
	background: #ffe271 !important;
	color: #132941 !important;
}
.mco-overlay.datepicker .mco-overlay-toolbar {
	background: #ffe271 !important;
	color: #132941 !important;
}

/*** Routeplanner ***/

.mco-view-component-container-extension-route-planner {
	position: relative;
}
.mco-view-component-container-extension-route-planner > div {
	position: relative;
	z-index: 1;
}
.extension-route-planner__input input {
	margin-top: 4px !important;
}

/*** E-Book ***/

.mco-ebook-teaser-01 {
	background: none !important;
	max-width: 980px !important;
	padding: 0 !important;
}
.mco-ebook-teaser-01__title h2 {
	color: #132941 !important;
	font-size: clamp(17px, 8vw, 20px) !important;
	font-family: "Gatter Sans" !important;
	font-weight: 700 !important;
	line-height: 1.5 !important;
	text-transform: uppercase !important;
}
.mco-ebook-teaser-01__download svg path {
	fill: none !important;
}
.mco-ebook-teaser-01__download button:hover svg > g *:not(rect) {
	stroke: #ffffff !important;
}

/*** Search Overlay / Results ***/

.mco-overlay.overlay-search .mco-overlay-close {
	background: none !important;
}
.mco-overlay.overlay-search .mco-overlay-close svg path {
	fill: #ffffff !important;
}
.mco-search-results__item {
	background: none !important;
	border: 1px solid #132941 !important;
}
.mco-search-results .mco-search-results__item-button {
	background: #132941 !important;
	color: #ffffff !important;
	padding: 0 !important;
}
.mco-search-results .mco-search-results__item-button:hover {
	background: #132941 !important;
}
.mco-search-results .mco-search-results__item-button polyline {
	stroke: #ffffff !important;
}
.mco-search-results__item-title {
	color: #132941 !important;
	font-family: "Gatter Sans" !important;
	font-weight: 700 !important;
}

/*** Datepicker ***/

.MuiPickersCalendarHeader-switchHeader p {
	font-size: 16px !important;
}
.MuiDialogActions-root > button {
	margin-left: 16px !important;
}
/* .mco-datepicker-day.mco-datepicker-day--range-start,
.mco-datepicker-day.mco-datepicker-day--range-end {
	background: #132941 !important;
	color: #ffffff !important;
}
.mco-datepicker-day.mco-datepicker-day--range-start.mco-datepicker-day--range-mouseover,
.mco-datepicker-day.mco-datepicker-day--range-end.mco-datepicker-day--range-mouseover,
.mco-datepicker-day.mco-datepicker-day--range-mouseover,
.mco-datepicker-day.mco-datepicker-day--range-between {
	background: #132941 !important;
	color: #ffffff !important;
	opacity: 0.8 !important;
}
.mco-datepicker-day.mco-datepicker-day--previous-day-has-contingent {
	background: none !important;
}
.mco-datepicker-legend__color {
	margin-top: -3px !important;
}
.mco-datepicker-legend__color--selected {
	background: #132941 !important;
}
.mco-datepicker-weekdays__price {
	font-size: 9px !important;
}
.mco-datepicker-weekdays__day {
	margin-top: 4px !important;
} */

/*** Around Me ***/

.mco-view-component-container-extension-around-me .mco-map .leaflet-div-icon-custom-pulsating {
	left: 50% !important;
	margin-left: -12px !important;
}
.mco-view-component-container-extension-around-me .mco-map .marker-cluster-custom-icon {
	right: -4px !important;
	top: -4px !important;
}
.mco-view-component-container-extension-around-me .mco-map .leaflet-routes-pane svg path {
	stroke: #132941 !important;
	stroke-width: 5px !important;
}

/*** Recpatcha Badage ***/

.grecaptcha-badge {
	z-index: 1;
}

/*** Interaktionslayer ***/

.micado-ial-item-newsletter__form-button button,
.micado-ial-item-countdown__button,
.micado-ial-item-message__button,
.micado-ial-item-offer__button {
	align-items: center !important;
	background: none !important;
	border: 0 !important;
	box-shadow: none !important;
	color: #132941 !important;
	cursor: pointer !important;
	display: flex !important;
	font-size: 13px !important;
	font-weight: 700 !important;
	height: 30px !important;
	letter-spacing: 1px !important;
	line-height: 1 !important;
	min-width: 64px !important;
	margin-left: auto !important;
	padding: 0 !important;
	text-transform: uppercase !important;
	text-decoration: underline !important;
	text-decoration-thickness: 1.5px !important;
	text-underline-offset: 6px !important;
	width: auto !important;
}
.micado-ial-item-newsletter__form-button:hover button,
.micado-ial-item-countdown__button:hover,
.micado-ial-item-message__button:hover,
.micado-ial-item-offer__button:hover {
	background: none !important;
	opacity: 0.85 !important;
}
.micado-ial__close:hover,
.micado-overlay__close:hover {
	background: #f3efe7 !important;
}

/*** Shop ***/

.mco-shop {
	padding-bottom: 48px;
	padding-top: 32px;
}

/* Product-Detail */

.mco-shop-product-detail__right-column {
	top: 120px !important;
}
.mco-shop-product-detail-gallery .mco-gallery-02-swiper {
	background: #ffffff !important;
}
.mco-shop-product-detail-gallery .mco-gallery-02-swiper__item {
	display: flex !important;
	max-height: 533px !important;
}
.mco-shop-product-detail-gallery .mco-gallery-02-swiper__item img {
	object-fit: contain !important;
}
.mco-shop-product-detail-gallery .mco-gallery-02-swiper-nav__next,
.mco-shop-product-detail-gallery .mco-gallery-02-swiper-nav__prev {
	background: rgba(0, 0, 0, 0.2) !important;
}
.mco-shop-product-detail-gallery .mco-gallery-02-swiper-nav__next > div > div > svg,
.mco-shop-product-detail-gallery .mco-gallery-02-swiper-nav__prev > div > div > svg {
	width: 24px !important;
}

/* Filter */

.mco-filter-item__label--selected {
	color: #132941 !important;
}

/* Checkout */

.mco-shop-checkout__right-column {
	top: 120px !important;
}
.mco-shop-checkout__price-wrapper {
	background: none !important;
	padding: 0 !important;
}
.mco-shop-checkout__price {
	border-color: #132941 !important;
}
.mco-shop-checkout__headline {
	border-color: #132941 !important;
}
.mco-shop-checkout__header {
	border-color: #132941 !important;
}
.mco-shop-checkout-shipping-providers {
	border-color: #132941 !important;
}

/* Quantity */

.mco-shop-quantity button {
	border-color: rgba(0, 0, 0, 0.23) !important;
	border-width: 1px !important;
	padding: 0 !important;
}
.mco-shop-quantity button:first-child {
	border-right: 0 none !important;
}
.mco-shop-quantity button:last-child {
	border-left: 0 none !important;
}

/* Cart */

.mco-shop-cart-overlay__paper .mco-shop-cart-price {
	background: #f3efe7 !important;
}
.mco-shop-cart-overlay__paper .mco-shop-cart-sticky {
	background: #f3efe7 !important;
}
.mco-shop-cart-overlay__paper .mco-shop-cart-buttons {
	background: #f3efe7 !important;
}
.mco-shop-cart-item {
	border-color: #132941 !important;
}
.mco-shop-cart-price {
	border-color: #132941 !important;
}

/* Address */

.mco-shop-address-item {
	border-color: #132941 !important;
}

/* My account*/

.mco-shop-myaccount-menu__link[data-identifier="products"],
.mco-shop-myaccount-menu__tab[data-identifier="products"] {
	display: none !important;
}
.mco-shop-myaccount-customer-overview__item {
	border-color: #132941 !important;
}
.mco-shop-myaccount-customer-overview__item:last-child {
	border: 0 none !important;
}
.mco-shop-myaccount-orders-list th,
.mco-shop-myaccount-orders-list td {
	border-color: #132941 !important;
}
.mco-shop-myaccount-order-details__addresses {
	border-color: #132941 !important;
}

@media all and (max-width: 600px) {
	/* Product-Overview */

	.mco-shop-product-overview-list {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
}

@media all and (max-width: 450px) {
	/* Product-Overview */

	.mco-shop-product-overview-list {
		grid-template-columns: 1fr !important;
	}
}

@import url("./fonts.css");
@import url("./custom.css");
@import url("./collaborate.css");
@import url("./vioma.css");

html {
	font-size: 100%;
	min-height: calc(100% + env(safe-area-inset-top));
	/* scrollbar-gutter: stable; */
}
body {
	background: #f3efe7;
	margin: 0;
}
a {
	color: #132941;
	font-weight: 700;
	text-decoration: none;
}
p {
	margin: 0;
}
u {
	text-decoration: underline;
	text-underline-offset: 2px;
	text-decoration-thickness: 1.5px;
}
ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
#root {
	padding: 0;
	position: relative;
}
.MuiSkeleton-text {
	transform: none !important;
}
.mco-collapsing + .mco-collapsing {
	margin-top: -80px;
}

.mco-html-02 {
	position: relative;
	z-index: 1;
}
.vri-page .vri-button {
	font-size: 13px !important;
	font-weight: 700 !important;
	line-height: 1 !important;
	letter-spacing: 1px !important;
	text-transform: uppercase !important;
	min-height: 40px !important;
	display: flex !important;
	align-items: center !important;
	padding: 11px 24px 8px !important;
}
.vri-page .vri-button span {
	font-size: 13px !important;
	font-weight: 700 !important;
	line-height: 1 !important;
	letter-spacing: 1px !important;
	text-transform: uppercase !important;
}
.cst .vsc-navigation-fixed {
	position: sticky !important;
	top: 89px !important;
}
.vri-pagination-item .vri-pagination-link {
	background: #f3d76c !important;
	color: #132941 !important;
}
.vri-pagination-item.vri-pagination-current .vri-pagination-link {
	background: #132941 !important;
	color: #f3efe7 !important;
}
#vri-container-17779 .vri-request-item,
#vri-overlay .vri-container-17779 .vri-request-item {
	padding: 0 !important;
}
.vri-package-buttons > .vri-button {
	background: #9fbd80 !important;
}
.vri-package-list .vri-pagination-item .vri-pagination-link {
	background: #9fbd80 !important;
	color: #132941 !important;
}
.vri-package-list .vri-pagination-item.vri-pagination-current .vri-pagination-link {
	background: #132941 !important;
	color: #f3efe7 !important;
}

@font-face {
	font-display: block;
	font-family: "Junicode";
	font-style: normal;
	font-weight: 400;
	src: local(""), url("../fonts/junicode.woff") format("woff");
}
@font-face {
	font-display: block;
	font-family: "Gatter Sans";
	font-style: normal;
	font-weight: 400;
	src: local(""), url("../fonts/gatter-sans-regular.woff2") format("woff2"),
		url("../fonts/gatter-sans-regular.woff") format("woff"),
		url("../fonts/gatter-sans-regular.ttf") format("ttf");
}
@font-face {
	font-display: block;
	font-family: "Gatter Sans";
	font-style: normal;
	font-weight: 500;
	src: local(""), url("../fonts/gatter-sans-medium.woff2") format("woff2"),
		url("../fonts/gatter-sans-medium.woff") format("woff");
}
@font-face {
	font-display: block;
	font-family: "Gatter Sans";
	font-style: normal;
	font-weight: 700;
	src: local(""), url("../fonts/gatter-sans-bold.woff2") format("woff2"),
		url("../fonts/gatter-sans-bold.woff") format("woff");
}
